$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; // Theme: CNVC Overrides
// Target: common scss
// Last Edited: 2024-05-14 19:54:41 UTC
/* Removes some items from user prferences if person is not staff member*/
body:not(.staff) {
    .user-nav__preferences-security,
    .user-nav__preferences-tracking,
    .user-nav__preferences-users {
        display: none !important;
    }    
}

.sidebar-wrapper .sidebar-footer-wrapper .btn-flat.add-section {
    display: none;
}
.staff .sidebar-wrapper .sidebar-footer-wrapper .btn-flat.add-section {
    display: inline;
}

/* Remove some choices from under posts near the Reply button */
#topic-footer-button-bookmark {
    display:none;
}

div.topic-notifications-button     {
    display:none;
}

/*Remove some icons from the reply/Compose editor */
.local-dates {
    display:none;
}
button.quote {
    display: none;
}
details.toolbar-popup-menu-options {
    display:none;
}
.options.select-kit.single-select.dropdown-select-box.toolbar-popup-menu-options {
    display:none;
}

/* Removes "Web Site" standard field from profile edit screen

#edit-website {
    display:none;
}
#user-content > form > div.control-group.pref-website > label {
    display:none;
} */

#user-card > div > div.card-row.first-row > div.names > h2.username {
    display:none;
}
